footer {
  &.sticky_footer {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 100%;
    line-height: 55px;
    color: var(--color-gray-2);
  }
}

@media (min-width: 992px) {
  footer {
    &.sticky_footer {
      width: calc(100% - 300px);
    }
  }
  .topper_main {
    &.sidenav_toggled {
      footer {
        &.sticky_footer {
          width: calc(100% - 55px);
        }
      }
    }
  }
}
