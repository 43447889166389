*,
*:after,
*:before {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block;
}

a,
blockquote,
body,
dd option,
div,
dl,
figure,
form,
h1,
h2,
h3,
h4,
html,
img,
input,
label,
li,
ol,
p,
section,
select,
span,
table,
tbody,
td,
tfoot,
th,
thead,
tr,
ul {
  border: 0;
  margin: 0;
  padding: 0;
}

ol,
ul {
  list-style: none;
}

html {
  scroll-behavior: smooth;
}

body {
  text-rendering: auto;
  line-height: 1.6;
}

a {
  display: inline-block;
  text-decoration: none;
}

img,
picture {
  max-width: 100%;
}

button,
input,
select,
textarea {
  font: inherit;
}

button,
html input[type='button'],
input[type='reset'],
input[type='submit'] {
  cursor: pointer;
}

button {
  background-color: transparent;
  border: none;
}

@media (prefers-reduced-motion: reduce) {
  html {
    scroll-behavior: auto;
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin-bottom: 0;
}
