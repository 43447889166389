@use '../utility/reuseble' as *;
@use '../utility' as *;

.form-control {
  padding: 0.5rem 1.5rem;
}
.form-group {
  margin-bottom: 1rem;
}
.filter_dropdown {
  padding: 0 !important;
  border-radius: 16px !important;
}
.product_add_main {
  .box_main {
    background-color: var(--color-white);
    border-radius: 16px 16px 0px 0px;
  }
  .form_input {
    border-radius: 40px;
    box-shadow: none;
    border: 1px solid var(--color-gray-4);
    font-size: var(--font-16);
    color: var(--color-navy-2);
    font-family: var(--font-family-2);
  }
  .question_dropdown {
    border: 1px solid var(--color-gray-4);
    border-radius: 40px;
    &:disabled {
      cursor: not-allowed;
      border: 1px solid red;
    }
  }
  .sub_question {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    margin-left: 2rem;
    margin-top: 0.5rem;
  }
  .header_top {
    padding: 0.5rem 1.5rem;
    border-bottom: 1px solid var(--color-gray-4);
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include mediaMax(md) {
      padding: 0.5rem;
    }
    @include mediaMax(sm) {
      flex-direction: column;
      align-items: start;
    }
    h3 {
      font-size: var(--font-21);
      @extend %fw-500;
      color: var(--color-navy-2);
      padding: 1rem 0;
      font-family: var(--font-family-2);
      @include mediaMax(lg) {
        font-size: 1.2rem;
        padding: 0.3rem 0;
      }
    }
  }
  .middle_main_2 {
    padding: 0.7rem;
    background-color: var(--color-white);
    border-bottom: 1px solid var(--color-gray-4);
    @include mediaMax(md) {
      padding: 0.5rem;
    }
    .keywords_main {
      width: 100%;
      border: 1px solid var(--color-gray-4);
      padding: 0.25rem 0.25rem;
      border-radius: 40px;
      @include mediaMax(md) {
        padding: 0.25rem 1rem;
      }
      a {
        padding: 0.8rem 1.25rem;
        @extend %fw-500;
        border-radius: 40px;
        font-size: var(--font-16);
        color: var(--color-navy-2);
        font-family: var(--font-family-2);
        background-color: var(--color-gray);
        &.link_text {
          background-color: var(--color-blue-sky-2);
          color: var(--color-primary);
        }
        @include mediaMax(md) {
          padding: 0.5rem 1rem;
        }
      }
    }
    .add_plus {
      padding: 0.9rem;
      border: dashed 2px var(--color-primary);
      width: 200px;
      background-color: var(--color-blue-sky-2);
      border-radius: 28px;
      @extend %fw-500;
      margin-top: 1rem;
      font-family: var(--font-family-2);
      text-align: center;
      justify-content: center;
      font-size: var(--font-16);
      display: flex;
      align-items: center;
      color: var(--color-primary);
      @include mediaMax(md) {
        padding: 0.7rem 1rem;
      }
    }
    .img_des_1 {
      @extend %fw-500;
      font-size: var(--font-16);
      color: var(--color-navy-2);
      font-family: var(--font-family-2);
      margin-bottom: 0.4rem;
    }
    .img_des_2 {
      @extend %fw-500;
      font-size: var(--font-16);
      color: var(--color-gray-6);
      font-family: var(--font-family-2);
      margin-bottom: 1.5rem;
    }
    .custom_radius {
      border-radius: 16px;
      width: 100%;
    }
    .upload_text {
      padding: 0.9rem;
      border: dashed 2px var(--color-primary);
      background-color: var(--color-blue-sky-2);
      border-radius: 28px;
      @extend %fw-500;
      font-family: var(--font-family-2);
      text-align: center;
      justify-content: center;
      font-size: var(--font-18);
      display: flex;
      align-items: center;
      flex-direction: column;
      height: 150px;
      width: 150px;
      color: var(--color-primary);
      @include mediaMax(md) {
        padding: 0.7rem 1rem;
      }
    }

    .add_box_main {
      border-bottom: 1px solid var(--color-gray-4);
      padding: 1rem 0;
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
      display: flex;
      align-items: center;
      .product_add_img {
        border-radius: 10px;
        min-width: 64px;
        height: 64px;
        margin-right: 16px;
      }
      .des_one {
        @extend %fw-500;
        font-size: var(--font-21);
        color: var(--color-navy-2);
        font-family: var(--font-family-2);
        @include mediaMax(md) {
          font-size: 1.2rem;
        }
      }
      .des_two {
        @extend %fw-500;
        font-size: var(--font-16);
        color: var(--color-gray-6);
        font-family: var(--font-family-2);
      }
      .delete_icon {
        background-color: var(--color-gray);
        height: 56px;
        width: 56px;
        border-radius: 40px;
        padding: 0.5rem;
        display: flex;
        justify-content: center;
        cursor: pointer;
        @include mediaMax(md) {
          height: 40px;
          width: 40px;
        }
      }
    }
    .specific_questions_table {
      table {
        tr {
          th {
            color: var(--color-gray-6);
            font-family: var(--font-family-2);
            font-size: var(--font-16);
            @extend %fw-500;
          }
          td {
            .sort_by_btn {
              width: 100%;
              min-width: 190px;
              border: 1px solid var(--color-gray-4);
              text-align: left;
              border-radius: 40px;
              box-shadow: none;
              color: var(--color-navy-2);
              font-family: var(--font-family-2);
              font-size: var(--font-16);
              padding: 1rem 1.25rem;
              &::after {
                background: url(../../images/chevron-down.svg);
                background-repeat: no-repeat;
                content: '';
                border: 0;
                width: 21px;
                height: 21px;
                position: absolute;
                right: 16px;
                top: 17px;
              }
            }
            .hover_effect {
              color: var(--color-navy-2);
              font-family: var(--font-family-2);
              font-size: var(--font-16);
              &:focus {
                background-color: var(--color-sky);
                border-radius: 6px;
                color: var(--color-blue-navy-2);
              }
              &:hover {
                background-color: var(--color-sky);
                border-radius: 6px;
                color: var(--color-blue-navy-2);
              }
              &.active {
                background-color: var(--color-sky);
                border-radius: 6px;
                color: var(--color-blue-navy-2);
              }
            }

            img {
              min-width: 20px;
            }
          }
        }
        @include mediaMax(xl) {
          min-width: 1000px;
        }
      }
    }
    .hover_effect_main {
      cursor: pointer;
      &:hover {
        .hover_effect_child {
          display: flex;
        }
      }

      .hover_effect_child {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background-color: rgba(0, 0, 0, 0.4);
        border-radius: 16px;
        display: none;
        align-items: center;
        justify-content: center;
      }
    }
  }
  .footer_main {
    padding: 1.5rem;
    background-color: var(--color-white);
    border-radius: 0px 0px 16px 16px;
    display: flex;
    align-items: center;
    @include mediaMax(md) {
      padding: 0.5rem;
    }
    @include mediaMax(sm) {
      display: block;
    }
  }
  .middle_title {
    font-size: var(--font-21);
    @extend %fw-700;
    color: var(--color-navy-2);
    margin-bottom: 1rem;

    @include mediaMax(lg) {
      font-size: 1.2rem;
      margin-bottom: 1rem;
    }
  }
  .blog-buttons {
    height: 50px;
  }
}
.custom_file_upload {
  border: 1px solid #ccc;
  display: inline-block;
  padding: 10px 22px;
  cursor: pointer;
}
.strengths_table {
  width: inherit;
  table-layout: fixed;
  margin-left: 40px;
  margin-top: 20px;
  thead {
    th {
      background-color: #ddd;
      color: black;
      border: 1px solid var(--color-gray-2) !important;
    }
  }
  tbody {
    td {
      border: 1px solid var(--color-gray-2) !important;
    }
  }
}
.step_custom {
  .wizard_inner {
    position: relative;
    margin-bottom: 1rem;
    width: 50%;
    @include mediaMax(xl) {
      width: 70%;
    }
    ul {
      position: relative;
      display: flex;
      flex-wrap: wrap;
      li.active {
        span {
          background-color: var(--color-primary);
          color: var(--color-white);
          border-color: var(--color-primary);
        }

        a {
          i {
            color: var(--color-navy-2);
          }
        }
      }
      li {
        width: 25%;
        position: relative;
        text-align: center;
        a {
          display: block;
          border-radius: 100%;
          padding: 0;
          background-color: transparent;
          position: relative;
          top: 0;
          i {
            position: relative;
            white-space: pre-line;
            word-break: break-word;
            display: block;
            text-align: center;
            font-size: 22px;
            @extend %fw-500;
            font-family: var(--font-family-2);
            color: var(--color-gray-2);
            font-style: normal;
            @include mediaMax(xl) {
              font-size: 1.2rem;
            }
            @include mediaMax(lg) {
              font-size: 1rem;
            }
            @include mediaMax(md) {
              display: none;
            }
          }
          span {
            width: 50px;
            height: 50px;
            line-height: 60px;
            display: inline-block;
            border-radius: 50%;
            background-color: var(--color-gray);
            z-index: 2;
            display: inline-block;
            position: relative;
            text-align: center;
            color: var(--color-gray-6);
            font-size: var(--font-21);
            @extend %fw-700;
            @include mediaMax(md) {
              width: 40px;
              height: 40px;
              line-height: 40px;
              font-size: 1.2rem;
            }
          }
        }
        &:first-child {
          span {
            background-color: var(--color-primary);
            color: var(--color-white);
            border-color: var(--color-primary);
          }
          &::before {
            display: none;
          }
        }
      }
    }
  }
  .connecting_line {
    height: 2px;
    background-color: var(--color-gray-4);
    position: absolute;
    width: 75%;
    margin: 0;
    // left: 50px;
    left: calc(6.25% + 50px);
    right: 0;
    top: 25px;
    z-index: 1;
    // @include mediaMax(xxl) {
    //   left: 8rem;
    // }
    // @include mediaMax(xl) {
    //   left: 6rem;
    // }
    @include mediaMax(md) {
      top: 20px;
      left: 40px;
    }
    // @include mediaMax(sm) {
    //   left: 3rem;
    // }
  }
}

.custom_tab_main {
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    border-bottom: 1px solid var(--color-gray-4);
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 2.5rem;
    li {
      margin-right: 2rem;

      a {
        padding: 1rem 0rem 1rem 0rem;
        color: var(--color-gray-5);
        font-size: var(--font-16);
        @extend %fw-500;
        font-family: var(--font-family-2);
        &.active {
          color: var(--color-primary);
          border-bottom: 2px solid var(--color-primary);
        }
      }
    }
  }
}
.condensed_table {
  td {
    padding: 0px;
  }
}
// prevent edtior overlap on the menu
.custom-select__menu {
  z-index: 2 !important;
}
