.static_question {
  position: relative;
  > div {
    margin-top: 16px;
  }
  &--label {
    z-index: 2;
    font-size: 14px;
    position: absolute;
    transform: translate(0, -100%);
    font-weight: 500;
    padding: 2px 10px;
    line-height: 16px;
    color: #495057;
  }
}
