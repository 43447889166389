@use '../utility' as *;
@use '../utility/reuseble' as *;

// button

.btn {
  padding: 0.9rem 2rem;
  font-size: var(--font-16);
  border-radius: 28px;
  line-height: normal;
  @extend %fw-400;
  position: relative;
  cursor: pointer;
  @include mediaMax(md) {
    padding: 0.5rem 1.5rem;
    font-size: 1rem;
  }
  &-white {
    color: var(--color-black);
    background-color: var(--color-white);
  }
  &-green {
    color: var(--color-white);
    background-color: var(--color-green);
    &:hover {
      color: var(--color-white);
      background: rgba(115, 209, 61, 0.7);
    }
  }
  &-delete {
    color: var(--color-red);
    background-color: var(--color-red-light);
    @extend %fw-500;
    font-family: var(--font-family-2);
    &:hover {
      color: var(--color-red);
      background: rgba(255, 231, 237, 0.7);
    }
  }
  &-primary {
    color: var(--color-white);
    background-color: var(--color-primary);
  }

  &-outline {
    border: 2px solid var(--color-white);
    background-color: transparent;
    color: var(--color-white);
  }
  &-sky {
    color: var(--color-navy-2);
    background-color: var(--color-sky);
    @extend %fw-500;
    font-family: var(--font-family-2);
    &:hover {
      color: var(--color-navy-2);
      background: rgba(220, 239, 255, 0.7);
    }
  }
  &:focus {
    box-shadow: 0 0 0 0rem rgb(0 0 0 / 0%) !important;
  }
}

// Section: Keyframe
