@use '../utility/reuseble' as *;

.text {
  &-purple {
    color: var(--color-primary);
  }
  &-white {
    color: var(--color-white);
  }
  &-dull-white {
    color: hsl(var(--color-white-hsl) / 0.7);
  }
  &-purple-grdient {
    background: linear-gradient(92.84deg, #ddd 38.51%, #ddd 53.47%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }
  &-black {
    color: hsl(var(--color-black));
  }
  &-yellow {
    color: var(--color-yellow);
  }
}
.fs {
  &-16 {
    font-size: 16px;
  }
  &-12 {
    font-size: 12px;
  }
  &-11 {
    font-size: 11px;
  }
  &-13 {
    font-size: 13px;
  }
  &-14 {
    font-size: 14px;
  }
  &-15 {
    font-size: 15px;
  }
}
