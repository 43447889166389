@use 'breakpoint' as *;
@use 'function' as *;

// Makes the @content apply to the given breakpoint and wider.
// $name : map-key   $mapBreakpoints: full map data

// Media of at least the minimum breakpoint width.
// Ex: @include mediaMin(sm) { Addition content adding for }
@mixin mediaMin($name, $mapBreakpoints: $grid-breakpoints) {
  $min: breakpoint-min($name, $mapBreakpoints);

  @if $min {
    $val: $min + 'em';
    @media (min-width: $val) {
      @content; //additional content adding for
    }
  } @else {
    @error "Not find key in Map " $name;
  }
}

// Media of at least the Maximum breakpoint width.
// Ex: @include mediaMax(sm) { Addition content adding for }
@mixin mediaMax($name, $mapBreakpoints: $grid-breakpoints) {
  $max: breakpoint-max($name, $mapBreakpoints);

  @if $max {
    $val: $max + 'em';

    @media (max-width: $val) {
      @content; //additional content adding for
    }
  } @else {
    @error "Not find key in Map " $name;
  }
}

// Media of at least the Minmum & Maximum breakpoint width.
// Ex: @include mediabetween(sm,md) first min, second max{ Addition content adding for }
@mixin mediaBetween($lower, $upper, $breakpoints: $grid-breakpoints) {
  $min: breakpoint-min($lower, $breakpoints);
  $max: breakpoint-max($upper, $breakpoints);

  @if $min != null and $max != null {
    $val: $min + 'em';
    $valmax: $max + 'em';

    @media (min-width: $val) and (max-width: $valmax) {
      @content;
    }
  } @else {
    @error "use proper breakpoint thanks" $lower $upper;
  }
}

@mixin mediaMinUpward($name, $breakpoints: $grid-breakpoints) {
  $min: breakpoint-min($name, $breakpoints);
  @if $min {
    $val: $min + 'em';
    @media (min-width: $val) {
      @content;
    }
  } @else {
    @content;
  }
}
