@use '../utility/reuseble' as *;
@use '../utility' as *;

.login_main {
  .middle_main {
    background-color: var(--color-white);
    padding: 2rem;
    border-radius: 16px;
    .right_img {
      right: 30px;
      top: 47px;
      @include mediaMax(lg) {
        top: 54px;
      }
      @include mediaMax(md) {
        top: 52px;
      }
    }
    .login_btn {
      padding: 0.8rem 5rem;
    }
  }
}
