.general_health_main h2 {
  color: #021d33;
  font-weight: bold;
  font-size: 28px;
}
.general_health_main p.title {
  color: #354a5c;
  font-size: 17px;
  font-weight: 400;
}
.general_health_main .second {
  color: #021d33;
  font-size: 20px;
  font-weight: 400;
  padding-right: 50px;
}
.general_health_main .second p {
  margin-bottom: 0;
}
.general_health_main .off_btn.button_active {
  color: #fff;
  background: transparent linear-gradient(105deg, #58b3ff 0%, #088fff 100%) 0%
    0%;
  border-color: #088fff;
}
.general_health_main .off_btn.button_inactive {
  border: 1px solid rgb(218, 220, 224);
}
.general_health_main .off_btn {
  padding: 10px 30px;
  background: #fff;
  color: #000;
}
.heightWeightUnit {
  color: grey;
}
.heightWeightSwitch {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.heightWeightInput {
  width: 16%;
  height: 39px;
  border-radius: 10px;
}
.doubleHeightWeightInput {
  width: 16%;
}
.doubleHeightWeightInput input {
  height: 39px;
  border-radius: 10px;
}
.general_health_main .off_btn.button_active:focus {
  box-shadow: none;
  outline: 0;
}
.general_health_main .custom_mb_set {
  margin-bottom: 20px;
}
.general_health_main .cancel_btn {
  background: #dcefff;
  color: #021d33;
  font-weight: 500;
  font-family: 'dm_sansmedium';
}
.general_health_main .btn_pd {
  padding: 12px 40px;
}
.general_health_main .input_feet {
  padding: 10px 18px;
  width: 92px;
}

.custom-border {
  border: 1px solid rgb(218, 220, 224);
}

@media (max-width: 767px) {
  .step_custom .wizard > div.wizard_inner {
    margin-bottom: 120px;
  }
  .general_health_main h2 {
    font-size: 24px;
  }
  .general_health_main p.title {
    font-size: 16px;
  }
  .general_health_main .second {
    font-size: 18px;
    padding-right: initial;
  }
  .general_health_main .btn_pd {
    padding: 8px 15px;
    font-size: 14px;
  }
  .general_health_main .off_btn {
    padding: 6px 24px;
    background: #fff;
    color: #000;
    font-size: 14px;
  }
  .general_health_main .input_feet {
    padding: 6px 18px;
    width: 130px;
  }
  .img_name {
    font-size: 14px;
  }
}

@media (max-width: 576px) {
  .heightWeightSwitch {
    display: flex;
    flex-direction: column;
    align-items: baseline;
  }
  .img_name {
    font-size: 12px;
  }
  .heightWeightInput {
    width: 32%;
  }
  .doubleHeightWeightInput {
    width: 32%;
  }
}
.img_name {
  cursor: pointer;
}
.img_name:hover {
  color: blue;
}
.styled_checkbox {
  border: 2px solid #ccc;
  content: '';
  margin-right: 10px;
  display: block !important;
  vertical-align: text-top;
  width: 15px;
  height: 15px;
  background: white;
  border-radius: 3px;
  margin-top: 0;
}
.option {
  border: 1px solid rgb(218, 220, 224);
  margin: 1px;
  margin-bottom: 10px;
  padding: 8px;
  border-radius: 5px;
}

.step_custom .wizard .nav_tab_custom {
  position: relative;
  margin-bottom: 0;
  border-bottom-color: transparent;
}
.step_custom .wizard > div.wizard_inner {
  position: relative;
  margin-bottom: 180px;
}
.step_custom .connecting_line {
  height: 2px;
  background: #e0e0e0;
  position: absolute;
  margin: 0;
  left: 70px;
  right: 0;
  top: 30px;
  z-index: 1;
}
.step_custom .wizard .nav_tab_custom > li.active > a,
.step_custom .wizard .nav_tab_custom > li.active > a:hover,
.step_custom .wizard .nav_tab_custom > li.active > a:focus {
  color: #555555;
  cursor: default;
  border: 0;
  border-bottom-color: transparent;
}
.step_custom span.round_tab {
  width: 60px;
  height: 60px;
  line-height: 60px;
  display: inline-block;
  border-radius: 50%;
  background: #fff;
  z-index: 2;
  position: absolute;
  left: 55px;
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  color: #677785;
  font-weight: 500;
  border: 1px solid #ddd;
  font-family: 'dm_sansmedium';
}

.step_custom .wizard li.active span.round_tab {
  background: #34a3ff;
  color: #fff;
  border-color: #34a3ff;
}
.step_custom .wizard li.active span.round_tab i {
  color: #5bc0de;
}
.step_custom .wizard .nav_tab_custom > li {
  width: 25%;
  position: relative;
}
.step_custom .wizard li:after {
  content: ' ';
  position: absolute;
  left: 46%;
  opacity: 0;
  margin: 0 auto;
  bottom: 0px;
  border: 5px solid transparent;
  border-bottom-color: red;
  transition: 0.1s ease-in-out;
}
.step_custom .wizard .nav_tab_custom > li a {
  width: 30px;
  height: 30px;
  margin: 20px auto;
  border-radius: 100%;
  padding: 0;
  background-color: transparent;
  position: relative;
  top: 0;
}
.step_custom .wizard .nav_tab_custom > li a i {
  position: absolute;
  top: 75px;
  font-style: normal;
  font-weight: 400;
  white-space: nowrap;
  left: 50%;
  font-size: 20px;
  font-weight: 400;
  color: #000;
  text-align: center;
  margin-left: 20px;
}
.step_custom .wizard .nav_tab_custom > li a:hover {
  background: transparent;
}
.step_custom .wizard .inner_tab_main {
  position: relative;
  padding-top: 20px;
}
.step_custom .wizard .nav_tab_custom > li.active::before {
  position: absolute;
  content: '';
  width: 100%;
  left: -220px;
  height: 2px;
  background: #34a3ff;
  top: 30px;
  z-index: 1;
}

.step_custom .wizard .nav_tab_custom > li:first-child::before {
  display: none;
}

.general_health_container {
  padding-top: 30px;
}

.buttonClasses {
  border: 1px;
}

@media (min-width: 1199px) {
  .step_custom .baskte_text.round_tab {
    left: 65px;
  }
}

@media (max-width: 1199px) {
  .step_custom span.round_tab {
    left: 20px;
  }
  .step_custom .wizard .nav_tab_custom > li a i {
    font-size: 14px;
  }
}

@media (max-width: 991px) {
  .step_custom .wizard > div.wizard_inner {
    margin-bottom: 180px;
  }
}

@media (max-width: 767px) {
  .step_custom .wizard > div.wizard_inner {
    margin-bottom: 120px;
  }
}

@media (max-width: 576px) {
  .step_custom span.round_tab {
    width: 40px;
    height: 40px;
    line-height: 39px;
    font-size: 18px;
  }
  .step_custom .connecting_line {
    left: 20px;
    right: 0;
    top: 20px;
  }
  .step_custom .wizard .nav_tab_custom > li.active::before {
    left: 20px;
    top: 20px;
  }
  .step_custom .wizard .nav_tab_custom > li a i {
    display: none;
  }
  .step_custom .wizard > div.wizard_inner {
    margin-bottom: 60px;
  }
  .general_health_container {
    padding-top: 0px !important;
  }
}
