@use 'mixin' as *;
@use '../utility/variable' as *;
@use '../utility/maps' as *;

:root {
  --font-family: 'dm_sansregular';
  --font-family-2: 'dm_sansmedium';
  --font-11: 0.688rem;
  --font-12: 0.75rem;
  --font-14: 0.875rem;
  --font-15: 0.938rem;
  --font-16: 1rem;
  --font-18: 1.125rem;
  --font-20: 1.25rem;
  --font-21: 1.313rem;
  --font-24: 1.5rem;
  --font-26: 1.625rem;
  --font-28: 1.75rem;
  --font-32: 2rem;
  --font-48: 3rem;
  --gutter-bottom: 3rem;

  // colors
  @each $var, $val in $color {
    --color-#{$var}: #{$val};
  }

  // weights
  @each $var, $val in $weight {
    --weight-#{$var}: #{$val};
  }
}
