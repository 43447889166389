.custom_modal {
  h5 {
    &.modal_title {
      color: #021d33;
      font-size: 21px;
      font-weight: bold;
      margin-bottom: 0;
      line-height: 1.5;
    }
  }
  .modal_footer_custom {
    padding-left: 30px;
    padding-right: 30px;
    justify-content: flex-start;
    border-color: #9aa5ad;
  }
  .btn_pd {
    padding: 12px 40px;
  }
  .cancel_btn {
    background: #dcefff;
    color: #021d33;
    font-weight: 500;
  }
  .modal_content_custom {
    border-radius: 16px;
  }
  .header_top_modal {
    padding-left: 30px;
    padding-right: 30px;
    border-color: #9aa5ad;
    .close_right {
      &:focus {
        box-shadow: none;
      }
    }
  }
  .modal_costom_body {
    padding: 0;
  }
  input {
    border-radius: 40px;
    box-shadow: none;
    font-size: 16px;
    font-weight: 400;
    padding: 16px 20px;
    border-color: #9aa5ad;
    &.edit_text {
      border-color: #f6f7fb;
      background: #f6f7fb;
    }
    &::-webkit-input-placeholder {
      color: #9aa5ad;
    }
    &:-ms-input-placeholder {
      color: #9aa5ad;
    }
    &::placeholder {
      color: #9aa5ad;
    }
    &:focus {
      box-shadow: none;
      border-color: #9aa5ad;
    }
  }
  label {
    font-size: 16px;
    color: #021d33;
    font-weight: 500;
    margin-bottom: 10px;
  }
  select {
    border-radius: 40px;
    box-shadow: none;
    font-size: 16px;
    font-weight: 400;
    padding: 16px 20px;
    border-color: #9aa5ad;
    &:focus {
      box-shadow: none;
      border-color: #9aa5ad;
    }
  }
}
