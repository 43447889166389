@use '../utility/reuseble' as *;
@use '../utility' as *;

.product_detials_main {
  .box_main {
    background-color: var(--color-white);
    border-radius: 16px 16px 0px 0px;
  }
  .header_top {
    padding: 0.5rem 1.5rem;
    border-bottom: 1px solid var(--color-gray-4);
    display: flex;
    align-items: center;
    @include mediaMax(md) {
      padding: 0.5rem;
    }
    @include mediaMax(sm) {
      flex-direction: column;
      align-items: start;
    }
    h3 {
      font-size: var(--font-21);
      @extend %fw-500;
      color: var(--color-navy-2);
      padding: 1rem 0;
      font-family: var(--font-family-2);
      @include mediaMax(lg) {
        font-size: 1.2rem;
        padding: 0.3rem 0;
      }
    }
  }
  .middle_main {
    padding: 1.5rem;
    background-color: var(--color-white);
    border-radius: 0px 0px 16px 16px;
    @include mediaMax(md) {
      padding: 0.5rem;
    }
    .des_title {
      font-size: var(--font-24);
      @extend %fw-600;
      color: var(--color-navy-2);
      margin-bottom: 1rem;
      @include mediaMax(md) {
        font-size: 1.2rem;
      }
    }
    .des_title_2 {
      font-size: var(--font-18);
      @extend %fw-600;
      color: var(--color-navy-2);
      margin-bottom: 1rem;
      margin-top: 2rem;
      @include mediaMax(md) {
        font-size: 1rem;
      }
    }
    .des_1 {
      font-size: var(--font-16);
      margin-bottom: 0.2rem;
      color: var(--color-navy-2);
    }
    .des_2 {
      @extend %fw-500;
      font-size: var(--font-16);
      color: var(--color-navy-2);
      font-family: var(--font-family-2);
    }
    .des_3 {
      @extend %fw-400;
      font-size: var(--font-16);
      color: var(--color-navy-2);
    }
    .des_4 {
      @extend %fw-500;
      font-size: var(--font-18);
      color: var(--color-navy-2);
      font-family: var(--font-family-2);
    }
    .des_5 {
      @extend %fw-500;
      font-size: var(--font-16);
      color: var(--color-gray-6);
      font-family: var(--font-family-2);
    }
    .information_link {
      font-size: var(--font-16);
      color: var(--color-primary);
      font-family: var(--font-family-2);
      @extend %fw-500;
      margin-bottom: 1rem;
    }
    .custom_indicators {
      position: static;
      margin: 20px 0 0 0;
      justify-content: space-around;
      @include mediaMax(md) {
        flex-wrap: wrap;
        justify-content: inherit;
      }
      button {
        width: auto;
        height: auto;
        margin-right: auto;
        margin-left: 0px;
        text-indent: 0;
        background-color: transparent;
        border: 0;
        border-top: 0px solid transparent;
        border-bottom: 0px solid transparent;
        opacity: 1;
        @include mediaMax(md) {
          width: 70px;
          margin-right: 10px;
        }

        img {
          border-radius: 10px;
          width: 80%;
          min-height: 50px;
          @include mediaMax(md) {
            margin-bottom: 10px;
            width: 60px;
            height: 60px;
          }
        }
      }
      button.active {
        img {
          border: 2px solid var(--color-primary);
        }
      }
    }
    .slider_img {
      border-radius: 10px;
      width: 100%;
    }
    .carousel_control_pannel_left {
      display: none;
    }
    .carousel_control_pannel_right {
      display: none;
    }
    .box_1 {
      border: 1px solid var(--color-gray-4);
      padding: 1rem;
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
      display: flex;
      align-items: center;
      border-radius: 10px;
      img {
        border-radius: 10px;
        min-width: 72px;
        height: 72px;
        margin-right: 16px;
      }
    }
    .questions_table {
      table {
        tr {
          th {
            padding: 0.8rem 0;
          }
          td {
            padding: 0.5rem 0;
          }
        }
        @include mediaMax(md) {
          min-width: 1000px;
        }
      }
      .sub_menu_text {
        padding-left: 2rem;
        position: relative;
        &::before {
          position: absolute;
          left: 0;
          top: -10px;
          height: 24px;
          width: 24px;
          content: '';
          border-left: 1px solid var(--color-gray-5);
          border-bottom: 1px solid var(--color-gray-5);
        }
      }
    }
  }
}
.setting_main {
  .upload_custom {
    margin-top: 1rem;
    background-color: var(--color-gray);
    overflow: hidden;
    width: 170px;
    font-size: var(--font-16);
    color: var(--color-navy-2);
    font-family: var(--font-family-2);
    @extend %fw-500;
    margin-bottom: 1rem;
    padding: 15px 20px;
    border-radius: 30px;
    display: flex;
    position: relative;
    text-align: center;
    cursor: pointer;
    input {
      cursor: pointer;
      font-size: 50px;
      opacity: 0;
      position: absolute;
      right: 0;
      top: 0;
    }
  }
  .profile_pic {
    width: 180px;
    height: 180px;
    border-radius: 100%;
  }
  .right_img {
    right: 30px;
    top: 57px;
    @include mediaMax(lg) {
      top: 54px;
    }
    @include mediaMax(md) {
      top: 52px;
    }
  }
}

// order classes
.order_status {
  border-radius: 30px;
  padding: 5px 20px;
}
.order_btns {
  border-radius: 15px;
  padding: 0.5rem;
}
