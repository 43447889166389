@font-face {
  font-family: 'dm_sansregular';
  src: url('../../fonts/dmsans-regular-webfont.woff2') format('woff2'),
    url('../../fonts/dmsans-regular-webfont.woff') format('woff');

  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'dm_sansmedium';
  src: url('../../fonts/dmsans-medium-webfont.woff2') format('woff2'),
    url('../../fonts/dmsans-medium-webfont.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}
