@use 'mixin' as *;
@use '../utility/reuseble' as *;

.topper_main {
  .content_wrapper {
    padding: 4rem 0rem 0rem 0rem;
    @include mediaMin(lg) {
      margin: 40px 0px 40px 300px;
      padding: 0rem 1rem 1rem 1rem;
    }
  }
  &.sidenav_toggled {
    .content_wrapper {
      @include mediaMin(lg) {
        margin-left: 51px;
      }
    }
  }
}

.pagination {
  &__inner {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 1rem 0;
    gap: 1rem;
  }
  &__btn {
    border: 1px solid var(--color-gray-4);
    padding: 0.4rem 0.5rem;
    border-radius: 5px;
    img {
      min-width: 24px;
    }
    @include mediaMax(lg) {
      padding: 0.2rem;
    }
  }
  &__list {
    display: flex;
    align-items: center;
    gap: 0.6rem;
  }
  &__links {
    font-size: var(--font-14);
    color: var(--color-gray-6);
    @extend %fw-500;
    font-family: var(--font-family-2);
    width: 30px;
    height: 30px;
    border-radius: 5px;
    border: 1px solid var(--color-gray-4);
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all linear 300ms;
    @include mediaMin(lg) {
      width: 40px;
      height: 40px;
      font-size: var(--font-16);
    }
    &:hover,
    :focus {
      border-color: var(--color-primary);
      color: var(--color-primary);
    }
    &.active {
      border-color: var(--color-primary);
      color: var(--color-primary);
    }
    &.dots {
      border: 0;
      &:hover,
      :focus {
        background-color: unset;
        color: unset;
      }
      &.active {
        background-color: unset;
        color: unset;
      }
    }
  }
}

ol {
  &.breadcrumb {
    li {
      display: inline;
      font-size: var(--font-16);
      color: var(--color-navy-2);
      display: flex;
      align-items: center;
      & + li {
        &:before {
          padding: 5px 15px;
          color: var(--color-gray-5);
          content: '>';
          font-size: var(--font-21);
          position: relative;
          top: 2px;
        }
      }
      a {
        color: var(--color-navy-2);
        text-decoration: none;
        opacity: 0.5;
        &:hover {
          color: var(--color-navy-2);
          text-decoration: underline;
        }
      }
    }
  }
}

.text-wrap {
  word-break: break-all;
}

.color-red {
  color: red;
}

.submit-loader {
  margin-right: 6px;
}

.delete_modal_main {
  z-index: 9999;
  h5 {
    &.top_main_title {
      font-size: var(--font-16);
      color: var(--color-red);
      @extend %fw-500;
      font-family: var(--font-family-2);
    }
  }
  p {
    font-size: var(--font-16);
    color: var(--color-navy-2);
    @extend %fw-500;
    font-family: var(--font-family-2);
  }
}

.view_modal_main {
  z-index: 9999;
  h5 {
    &.top_main_title {
      font-size: var(--font-16);
      color: var(--color-navy-2);
      @extend %fw-500;
      font-family: var(--font-family-2);
    }
  }
}
.cursor-pointer {
  cursor: pointer;
}
