@use '../utility/mixin' as *;

html {
  position: relative;
  min-height: 100%;
  font-size: 0.935rem;
  @include mediaMin(xxxl) {
    font-size: 1rem;
  }
}
body {
  background-color: var(--color-gray);
  font-weight: var(--weight-400);
  font-family: var(--font-family);
}

.custom_modal {
  font-family: var(--font-family);
}

// tinymce domain is not registered box
.tox-notifications-container {
  display: none;
}
