@use '../utility' as *;
@use '../utility/reuseble' as *;
input:focus {
  outline: none;
}
.form {
  &-label {
    @extend %fw-500;
    font-size: var(--font-16);
    color: var(--color-navy-2);
    font-family: var(--font-family-2);
    margin-bottom: 1rem;
  }
  &-group {
    margin-bottom: 2rem;
    @include mediaMax(md) {
      margin-bottom: 1rem;
    }
  }
  &-input {
    border-radius: 40px;
    box-shadow: none;
    padding: 1rem 1.25rem;
    border: 1px solid var(--color-gray-4);
    @extend %fw-500;
    font-size: var(--font-16);
    color: var(--color-navy-2);
    font-family: var(--font-family-2);
    @include mediaMax(md) {
      padding: 0.8rem 1rem;
    }

    &::placeholder {
      color: var(--color-gray-5);
    }
    &:-ms-input-placeholder {
      color: var(--color-gray-5);
    }
    &::-ms-input-placeholder {
      color: var(--color-gray-5);
    }
    &:focus {
      box-shadow: none;
    }
  }
  &-textarea {
    border-radius: 10px;
    box-shadow: none;
    padding: 1rem 1.25rem;
    @extend %fw-500;
    font-size: var(--font-16);
    color: var(--color-navy-2);
    font-family: var(--font-family-2);
    border: 1px solid var(--color-gray-4);
    &::placeholder {
      color: var(--color-gray-5);
    }
    &:-ms-input-placeholder {
      color: var(--color-gray-5);
    }
    &::-ms-input-placeholder {
      color: var(--color-gray-5);
    }
    &:focus {
      box-shadow: none;
    }
    @include mediaMax(md) {
      padding: 0.8rem 1rem;
    }
  }
  &-select {
    border-radius: 40px;
    box-shadow: none;
    @extend %fw-500;
    font-size: var(--font-16);
    color: var(--color-navy-2);
    font-family: var(--font-family-2);
    padding: 1rem 1.25rem;
    &:focus {
      box-shadow: none;
    }
    @include mediaMax(md) {
      padding: 0.8rem 1rem;
    }
  }

  &-info {
    & > * {
      margin-bottom: 1rem;
    }
  }
}
.prise_text {
  border-radius: 40px;
  padding: 1rem 1.25rem;
  background-color: var(--color-gray);
  @include mediaMax(md) {
    padding: 0.2rem 1.25rem;
  }
}
input[type='radio'] {
  display: none;

  &:checked ~ .radio-label::before {
    border-color: var(--color-navy-dark);
  }
  &:checked ~ .radio-label::after {
    transform: translateY(-50%) scale(0.5);
  }
}
.radio-label {
  cursor: pointer;
  position: relative;
  font-size: 1rem;
  &::before {
    content: '';
    margin-right: 5px;
    width: 24px;
    height: 24px;
    display: inline-block;
    vertical-align: text-top;
    background-color: transparent;
    border: 3px solid var(--color-gray-3);
    border-radius: 50%;
    transition: #fff 400ms ease;
  }
  &::after {
    content: '';
    position: absolute;
    width: 24px;
    display: inline-block;
    vertical-align: text-top;
    height: 24px;
    background-color: var(--color-navy-dark);
    border: 2px solid var(--color-navy-dark);
    border-radius: 50%;
    top: 57%;
    left: 0%;
    transform: translateY(-50%) scale(0);
    transition: transform 400ms ease;
  }
}
.styled-checkbox {
  display: none;

  & + label {
    position: relative;
    cursor: pointer;
    padding: 0;
  }
  & + label:before {
    content: '';
    margin-right: 5px;
    display: inline-block;
    vertical-align: text-top;
    width: 22px;
    height: 22px;
    background: var(--color-gray);
    transition: all ease-in-out 300ms;
  }
  &:checked ~ label:before {
    background: var(--color-navy-dark);
  }
  &:checked ~ label:after {
    content: '';
    position: absolute;
    left: 5px;
    top: 12px;
    background: white;
    width: 3px;
    height: 3px;
    box-shadow: 2px 0 0 white, 4px 0 0 white, 4px -2px 0 white, 4px -4px 0 white,
      4px -6px 0 white, 4px -8px 0 white;
    transform: rotate(45deg);
  }
}
