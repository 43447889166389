.counter {
  &--arrow-inc,
  &--arrow-dec {
    border: 1px solid grey;
    background-color: #ddd;
    width: 20px;
    height: 20px;
    padding: 0;
    cursor: pointer;

    &:hover {
      background-color: #04a2a2;
    }

    &.js-noevent {
      pointer-events: none;
      background-color: #e6e6e6;
      border: none;
    }

    span {
      height: 90px;
      color: transparent;
      font-size: 0;
      &:before {
        display: flex;
        height: 100%;
        font-size: 23px;

        color: rgb(82, 82, 82);
        transition: opacity ease 0.3s;
        justify-content: center;
        align-items: center;
        content: '\25bc';
      }
    }
  }
  &--arrow-inc {
    span {
      &:before {
        transform: rotate(180deg);
      }
    }
  }
}
