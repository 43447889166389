@use '../utility/reuseble' as *;
@use '../utility' as *;

table {
  font-family: 'Trebuchet MS', Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
  th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: center;
    background-color: blue;
    color: white;
  }
}
table td,
table th {
  padding: 8px;
}
