%fw {
  &-800 {
    font-weight: var(--weight-800);
  }
  &-700 {
    font-weight: var(--weight-700);
  }
  &-600 {
    font-weight: var(--weight-600);
  }
  &-500 {
    font-weight: var(--weight-500);
  }
  &-400 {
    font-weight: var(--weight-400);
  }
}
